<template>
    <div v-if="attr !== ''" class="badge badge-primary my-2">{{attr}}</div>
</template>

<script>
    export default {
        name: "Attr",
        props: {
            attr: String,
        },
        calculated: {
            toAttrColour: function() {

            }
        }
    }
</script>

<style scoped>
    .badge {
        background-color: #26b6d4;
    }

</style>